import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import loadingGif from '../../../assets/loading.gif';
import { gamePartOptions, positionOptions } from '../../../constants';
import {
  selectNavigation,
  selectTableFilter,
  setCustomMetrics,
  setDataSettingsDataTemplate,
  setDataSettingsSelectedMetrics,
  setDataTemplate,
  setDisableMetricVideo,
  setGamePart,
  setOpenDataSettings,
  setPosition,
} from '../../../features';
import { useDataTemplateOptions, useLocationPaths } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { ButtonDataIcon } from '../../../icons';
import { IEntity, IMainFilterForm, INavigationContentKeys, ISelectOption } from '../../../types';
import { createClassNames, isFormValid, transformObjectKeysToKebabCase } from '../../../utils';
import { Button } from '../../Button';
import { Caption } from '../../Caption';
import { DisplayField } from '../../DisplayField';
import { SelectInput } from '../../SelectInput';
import { CountOfPlayersController } from '../../filterComponents';
import { ITableFilterConfig } from '../../types';
import './TableFilter.styles.scss';

export interface ITableFilterProps extends ITableFilterConfig {
  onClickLeaguePercentile?: () => void;
}

const classNames = createClassNames('table-filter');

export const TableFilter: FC<ITableFilterProps> = ({
  dataTemplateEntity = IEntity.players,
  disablePositionSelection,
  enableCountOfPlayers,
  enableGamePartField,
  submittedGoalkeeperOption,
  submittedPlayerOption,
  submittedTeamOption,
  extraElement,
  isPercentilesLoading,
  isPercentilesActive,
  percentilesButtonLabel,
  isSelectInputsDropUp,
  breakElements,
  onClickLeaguePercentile,
}) => {
  const { dataTemplate, position, customMetrics, gamePart } = useAppSelector(selectTableFilter);
  const { disableMetricVideo } = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { activePage, activeTab } = useLocationPaths(); // TODO: remove after implementing goalkeepers and teams videometrics
  const hasVideoLinkButton =
    (activePage === INavigationContentKeys.players ||
      activePage === INavigationContentKeys.goalkeepers) &&
    (activeTab === INavigationContentKeys.tabs ||
      (activePage === INavigationContentKeys.goalkeepers &&
        activeTab === INavigationContentKeys.gamelog));

  const dataTemplateOptions = useDataTemplateOptions(dataTemplateEntity);

  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;
  const disableCondition = !isFormValid(errors);

  const handleChangeDataTemplate = (value: ISelectOption) => {
    dispatch(setDataTemplate(value));
    dispatch(setCustomMetrics(undefined));
  };

  const handleChangePosition = (value: ISelectOption) => {
    dispatch(setPosition(value));
  };

  const handleToggleDisableMetricVideo = () => {
    dispatch(setDisableMetricVideo(!disableMetricVideo));
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          breakElements,
        }),
      })}
    >
      <Button
        label={ITranslationKeys.selectOwnData}
        iconComponent={<ButtonDataIcon />}
        onClick={() => {
          dispatch(setOpenDataSettings(true));
          dispatch(setDataSettingsDataTemplate(dataTemplate));
          if (customMetrics) {
            dispatch(setDataSettingsSelectedMetrics(customMetrics));
          }
        }}
      />
      <div>
        <Caption label={ITranslationKeys.dataTemplates} />
        <SelectInput
          onChange={value => handleChangeDataTemplate(value)}
          selected={dataTemplate}
          options={dataTemplateOptions}
          placeholder={ITranslationKeys.defaultSelectPlaceholder}
          variant='filter'
          disabled={disableCondition}
          dropUp={isSelectInputsDropUp}
          scrollable
        />
      </div>
      {enableGamePartField && (
        <div>
          <Caption label={ITranslationKeys.gamePeriod} />
          <SelectInput
            variant='filter'
            options={gamePartOptions}
            selected={gamePart}
            onChange={option => dispatch(setGamePart(option))}
          />
        </div>
      )}
      {enableCountOfPlayers && <CountOfPlayersController />}
      {!disablePositionSelection && (
        <div>
          <Caption label={ITranslationKeys.filterPositions} />
          <SelectInput
            onChange={value => handleChangePosition(value)}
            selected={position}
            options={positionOptions}
            placeholder={ITranslationKeys.defaultSelectPlaceholder}
            variant='filter'
            disabled={disableCondition}
            dropUp={isSelectInputsDropUp}
          />
        </div>
      )}
      {submittedGoalkeeperOption && (
        <div>
          <Caption label={ITranslationKeys.goalkeeper} />
          <DisplayField>{submittedGoalkeeperOption.label}</DisplayField>
        </div>
      )}
      {submittedPlayerOption && (
        <div>
          <Caption label={ITranslationKeys.player} />
          <DisplayField>{submittedPlayerOption.label}</DisplayField>
        </div>
      )}
      {submittedTeamOption && (
        <div>
          <Caption label={ITranslationKeys.team} />
          <DisplayField>{submittedTeamOption.label}</DisplayField>
        </div>
      )}
      {extraElement}
      {onClickLeaguePercentile && (
        <Button
          primary
          variant={isPercentilesActive ? 'normal-underlined' : 'gray'}
          iconComponent={
            isPercentilesLoading && isPercentilesActive ? (
              <img src={loadingGif} width={20} alt='loading-icon' />
            ) : undefined
          }
          iconPosition='left'
          label={percentilesButtonLabel ?? ITranslationKeys.leaguePercentile}
          onClick={onClickLeaguePercentile}
          disabled={disableCondition}
        />
      )}
      {hasVideoLinkButton && (
        <Button
          primary
          variant={disableMetricVideo ? 'gray' : 'normal-underlined'}
          label={t(ITranslationKeys.videoLinks).toString()}
          onClick={handleToggleDisableMetricVideo}
          disabled={disableCondition}
        />
      )}
    </div>
  );
};
