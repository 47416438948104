import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredFaceoffsSelector,
  filteredGoalkeepersShotsVideoMetricSelector,
  filteredPassesVideoMetricSelector,
  filteredShootoutsSelector,
  filteredShotsVideoMetricSelector,
  filteredZoneEntriesFilterDataSelector,
  filteredZoneExitsFilterDataSelector,
  selectVideoCenter,
  selectVideomapsFilter,
  setVideoRecord,
} from '../../features';
import { IMetricVideoLinkKeys, IShot, IZoneEntity } from '../../types';
import { createVideoRecordFromArray, mapShootoutToVideoInfo } from '../../utils';

export const useSetVideoRecordAtMetricDataLoad = () => {
  const filteredShots = useAppSelector(filteredShotsVideoMetricSelector);
  const filteredPasses = useAppSelector(filteredPassesVideoMetricSelector);
  const filteredFaceoffs = useAppSelector(filteredFaceoffsSelector);
  const filteredShootouts = useAppSelector(filteredShootoutsSelector);
  const { metricVideoLinkId } = useAppSelector(selectVideoCenter);
  const { entity, entityId, filterByProp, selectedGames } = useAppSelector(selectVideomapsFilter);
  const zoneEntries = useAppSelector(filteredZoneEntriesFilterDataSelector);
  const zoneExits = useAppSelector(filteredZoneExitsFilterDataSelector);
  const filteredGoalkeepersShots = useAppSelector(filteredGoalkeepersShotsVideoMetricSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (metricVideoLinkId === IMetricVideoLinkKeys.shots) {
      // TODO: Jirka - dát do selectoru
      const filtered = filteredShots.filter(shot => shot[filterByProp as keyof IShot] === entityId);
      const shots =
        entity.value === 'player'
          ? filtered
          : filterByProp === 'blocker'
          ? filtered
          : filteredShots;

      console.log('shots', shots);
      dispatch(setVideoRecord(createVideoRecordFromArray(shots)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.passes) {
      // TODO: Jirka - dát do selectoru - půjde spojit s shots.
      const passes =
        entity.value === 'player'
          ? filteredPasses.filter(pass => pass.playerId === entityId)
          : filteredPasses;

      console.log('passes', passes);
      dispatch(setVideoRecord(createVideoRecordFromArray(passes)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.faceoffs) {
      console.log('filteredFaceoffs', filteredFaceoffs);
      dispatch(setVideoRecord(createVideoRecordFromArray(filteredFaceoffs)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.entries) {
      // TODO: Jirka - dát do selectoru - půjde spojit s shots.
      const filtered = zoneEntries.filter(
        zone => zone[filterByProp as keyof IZoneEntity] === entityId,
      );

      const entries =
        entity.value === 'player' ? filtered : filterByProp === 'blocker' ? filtered : zoneEntries;

      console.log('entries', entries);
      dispatch(setVideoRecord(createVideoRecordFromArray(entries)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.exits) {
      // TODO: Jirka - dát do selectoru - půjde spojit s shots.
      const exits =
        entity.value === 'player'
          ? zoneExits.filter(zone => zone.playerId === entityId)
          : zoneExits;

      console.log('exits', exits);
      dispatch(setVideoRecord(createVideoRecordFromArray(exits)));
    }

    if (
      metricVideoLinkId === IMetricVideoLinkKeys.shootouts ||
      metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts
    ) {
      const isGoalkeeper = metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts;
      const filteredShootoutsByPlayer = filteredShootouts.filter(shootout =>
        isGoalkeeper
          ? shootout.goalkeeper.playerId === entityId
          : shootout.skater.playerId === entityId,
      );
      const filteredShootoutsByGames =
        selectedGames.length > 0
          ? filteredShootoutsByPlayer.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredShootoutsByPlayer;
      const shootouts = filteredShootoutsByGames.map(shootout => mapShootoutToVideoInfo(shootout));

      console.log('filteredShootouts', filteredShootoutsByGames);
      dispatch(setVideoRecord(createVideoRecordFromArray(shootouts)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShots) {
      const goalkeepersShots =
        selectedGames.length > 0
          ? filteredGoalkeepersShots.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredGoalkeepersShots;

      console.log('goalkeepersShots', goalkeepersShots);
      dispatch(setVideoRecord(createVideoRecordFromArray(goalkeepersShots)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredShots,
    filteredPasses,
    filteredFaceoffs,
    filteredShootouts,
    zoneEntries,
    zoneExits,
    filteredGoalkeepersShots,
  ]);
};
