import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { filteredMainFilterDataSelector, getPasses, getShots } from '../../features';
import {
  IFetchDataConfig,
  IMainFilterForm,
  IMetricVideoIds,
  IMetricVideoLinkKeys,
  IZonesFetchDataMode,
} from '../../types';
import {
  useFetchFaceoffs,
  useFetchShootouts,
  useFetchShotsOrPasses,
  useFetchZones,
} from '../fetch';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchDataByMetricLinkId = () => {
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const { fetchShots, fetchPasses, fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchFaceoffs } = useFetchFaceoffs();
  const { fetchZoneExits, fetchZoneEntries } = useFetchZones();
  const { fetchShootouts } = useFetchShootouts();

  const prepareBaseRequestBody = usePrepareBaseRequestBody();

  const fetchDataByMetricLinkId = (
    values: IMainFilterForm,
    entityId: string,
    metricIds: IMetricVideoIds,
    teamId?: string,
  ) => {
    const { metricLinkId, metricId, metricSubgroupId } = metricIds;
    const config: IFetchDataConfig = {
      customSelectedPlayerItems: [
        {
          id: '0',
          selectedPlayer: { value: entityId, label: entityId },
          isActive: true,
        },
      ],
      customSelectedTeamId: teamId,
    };

    if (metricLinkId === IMetricVideoLinkKeys.shots) {
      fetchShots(values, config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.passes) {
      fetchPasses(values, config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.faceoffs) {
      fetchFaceoffs(values, config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.entries) {
      const isDumpin =
        metricSubgroupId === 'nahozeni' ||
        metricSubgroupId === 'nahozeni_vedouci_ke_strele' ||
        metricId?.startsWith('dibaw');

      const fetchBoth = metricId?.includes('r_percent') || metricId?.includes('rf_percent');

      const mode = fetchBoth
        ? IZonesFetchDataMode.both
        : isDumpin
        ? IZonesFetchDataMode.dumpInOut
        : IZonesFetchDataMode.entry;

      fetchGamesAndToiForShotsOrPasses(values, {
        ...config,
        onGamesLoad: games => fetchZoneEntries(values, { ...config, games, mode }),
      });
    }

    if (metricLinkId === IMetricVideoLinkKeys.exits) {
      const isDumpout =
        metricSubgroupId === 'vyhozeni' || metricSubgroupId === 'vyhozeni_vedouci_ke_strele';

      const fetchBoth =
        metricSubgroupId === 'kontrolovane_vystupy' && metricId?.includes('r_percent');

      const mode = fetchBoth
        ? IZonesFetchDataMode.both
        : isDumpout
        ? IZonesFetchDataMode.dumpInOut
        : IZonesFetchDataMode.entry;

      fetchGamesAndToiForShotsOrPasses(values, {
        ...config,
        onGamesLoad: games => fetchZoneExits(values, { ...config, games, mode }),
      });
    }

    if (metricLinkId === IMetricVideoLinkKeys.shootouts) {
      fetchShootouts(values, 'videomaps', config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.goalkeepersShootouts) {
      fetchShootouts(values, 'goalkeepers', config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.goalkeepersShots) {
      const { requestBodyBase } = prepareBaseRequestBody(values);
      const competitionsUuids = filteredParts.map(part => part.id);

      dispatch(
        getShots({
          competitionsUuids,
          body: requestBodyBase,
          teamUuid: teamId || '',
          playerUuid: entityId,
        }),
      );

      if (metricId?.startsWith('cr.') || metricId?.startsWith('bn.')) {
        dispatch(
          getPasses({
            competitionsUuids,
            body: requestBodyBase,
            teamUuid: teamId || '',
            isAgainst: true,
          }),
        );
      }
    }
  };

  return {
    fetchDataByMetricLinkId,
  };
};
