import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  TableToolbar,
} from '../../../../components';
import { DEFAULT_CONTENT_TEMPLATE_NAMES } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGamelog,
  selectDataSettingsFilter,
  selectGamelog,
  selectMainFilter,
  selectMetrics,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useMainSubmittedValues,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IEntity,
  IExportRows,
  IGamelogIndividualStatsTableData,
  IGamelogRequestBody,
  IMainFilterForm,
} from '../../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  getScoreStateShortcut,
  handleExportToXLSX,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './GamelogContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchTeamGamelog = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchTeamGamelog = (data: Partial<IMainFilterForm>) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGamelogRequestBody = {
      ...requestBodyBase,
      formation: [],
      metrics: normalizeMetrics(DEFAULT_CONTENT_TEMPLATE_NAMES.gamelog, IEntity.teams),
    };

    const isSelectionValid = filteredParts.length > 0 && selectedTeam;

    if (isSelectionValid) {
      dispatch(
        getGamelog({
          competitionsUuids,
          teamUuid: selectedTeam.value,
          body: requestBody,
        }),
      );
    }
  };

  return fetchTeamGamelog;
};

const classNames = createClassNames('teams-gamelog-content');

export const GamelogContent = () => {
  const { isLoading } = useAppSelector(selectGamelog);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();
  const submittedValues = useMainSubmittedValues();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const isInitialMountRef = useRef(true);

  const fetchTeamGamelog = useFetchTeamGamelog();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchTeamGamelog(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const commonHeader = [
    t(ITranslationKeys.homeOnes),
    t(ITranslationKeys.awayOnes),
    t(ITranslationKeys.date),
    t(ITranslationKeys.result),
    'TOI',
  ];
  const tableRowCallback = (row: IGamelogIndividualStatsTableData): IExportRows => {
    const scoreState = getScoreStateShortcut(row.score.state);

    return {
      homeTeam: row.gameTeams.homeTeam.shortcut,
      awayTeam: row.gameTeams.awayTeam.shortcut,
      date: formatDateByLanguage(new Date(row.date), 'dd.MM.yyyy'),
      score: `${row.score.home}:${row.score.away}${scoreState ? t(scoreState) : ''}`,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  if (isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='teams-page__gamelog-content'>
      <TableToolbar
        dataTemplateEntity={IEntity.teams}
        centerTablePartRef={centerTablePartRef}
        scrollSizePx={130}
        submittedTeamOption={submittedValues.teamOption}
        disablePositionSelection
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX(
                t(ITranslationKeys.teamsGamelog),
                commonHeader,
                tableRowCallback,
                data,
              )
            }
          />
        }
      />
      <CommonTable<IGamelogIndividualStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
          entity={IEntity.teams}
        />
      )}
    </div>
  );
};
